* {
  outline: none;
  box-sizing: border-box;
  font-family: 'Titillium Web', sans-serif;
}

:root {
  --color-primary: rgb(29, 110, 167);
  --color-primary-transparent: rgba(29, 110, 167, 0.85);
  --color-primary-dark: rgb(6, 67, 110);
  --color-primary-dark-transparent: rgb(6, 67, 110, 0.85);
  --color-primary-darkest: rgb(5, 48, 78);
  --color-secondary: rgb(242, 182, 2);
  --color-secondary-dark: rgb(214, 161, 2);
  --color-success: rgb(29, 167, 70);
  --color-success-dark: rgb(20, 116, 49);
  --color-danger: rgb(214, 33, 33);
  --color-danger-dark: rgb(116, 20, 20);
  --color-elegant: rgb(43, 43, 43);
  --color-base: rgb(245, 246, 248);
  --color-light: rgb(240, 240, 240);
  --color-border: rgb(237, 237, 237);
  --color-detail: rgb(184, 184, 184);
  --color-default: rgb(121, 121, 121);
  --color-default-dark: rgb(92, 91, 91);
  --corner-radius: 10px;
  --corner-radius-small: 5px;
  --box-shadow: 0px 0px 10px 0px rgba(209, 209, 209, 0.6);
  --table-tr-space: 10px 20px 10px 20px;
  --font-size-bodytext: 16px;
  --font-size-small-heading: 16px;
}

body,
html {
  width: 100%;
  min-height: 100%;
  margin: 0;
  color: var(--color-elegant);
  font-family: 'Lato', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  margin: 0;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p,
li {
  font-size: 16px;
}

a {
  text-decoration: none;
}

.react-datepicker__tab-loop {
  z-index: auto !important;
}

.react-datepicker-popper {
  z-index: 2 !important;
}